import React, { useState } from "react";
import { Link } from "gatsby";
import { SliderButton } from "@typeform/embed-react";

const Header = () => {
  const [showDropdown, setShowDropdown] = useState(false);

  return (
    // <nav className="flex flex-wrap items-center justify-between p-4 w-full px-4 sticky top-0 z-50 bg-white">
    <nav className="flex flex-row items-center justify-between w-full sticky top-0 z-10 bg-white p-4">
      <a href="/">
        <svg
          width={185}
          height={34}
          viewBox="0 0 185 34"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_2_4)">
            <path
              d="M0 17.1V17A10.39 10.39 0 0110.73 6.59a9.46 9.46 0 018.55 4.47l-4.78 3.56a4.29 4.29 0 00-3.73-2.11c-2.26 0-3.92 2-3.92 4.44V17c0 2.59 1.62 4.55 4 4.55a4.78 4.78 0 003.85-2.07l4.7 3.4a9.93 9.93 0 01-8.92 4.59A10.32 10.32 0 010 17.1zM51.21 7h6.89l2.62 10.07L63.8 6.92h5.8l3.11 10.21L75.41 7h6.78L76 27.16h-6.06l-3.26-10.4-3.33 10.4h-6L51.21 7zM83.59 30.71l2.26-4.66a13.83 13.83 0 007 1.89c3.52 0 5.26-1.74 5.26-4.92v-.78a7.77 7.77 0 01-6.18 3C87 25.24 83 21.68 83 16v-.08c0-5.77 4-9.32 8.65-9.32A8 8 0 0198 9.36V7h7v15.2c0 3.67-.81 6.22-2.59 8-1.78 1.78-4.61 2.8-8.79 2.8a21.45 21.45 0 01-10.03-2.29zM98.1 16v-.08a4.1 4.1 0 00-8.18 0V16a4.1 4.1 0 008.18 0zM108 17.1V17c0-5.85 4.18-10.47 10.1-10.47 6.92 0 10.11 5.07 10.11 11v1.45h-13.4a4.14 4.14 0 004.33 3.29 6 6 0 004.44-2l3.89 3.22a10.466 10.466 0 01-8.7 4.07C112.41 27.49 108 23.31 108 17.1zm13.62-1.74c-.26-2.22-1.55-3.63-3.48-3.63s-3.11 1.44-3.48 3.63h6.96zM131.17 7h7v2.81a7.432 7.432 0 016-3.26c4.25 0 6.8 2.81 6.8 7.36V27h-7V16.17c0-2.18-1.14-3.37-2.81-3.37-1.67 0-3 1.19-3 3.37V27h-7l.01-20zM154.63 0H162v5.18h-7.33L154.63 0zm.15 7h7v20h-7V7zM164.92 17.1V17a10.399 10.399 0 013.155-7.483 10.392 10.392 0 017.575-2.927 9.46 9.46 0 018.54 4.47l-4.77 3.56a4.298 4.298 0 00-3.74-2.11 4.151 4.151 0 00-3.92 4.49c0 2.59 1.63 4.55 4 4.55a4.759 4.759 0 003.84-2.07l4.7 3.4a9.922 9.922 0 01-8.91 4.59 10.32 10.32 0 01-10.47-10.37z"
              fill="#231F20"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M47.84 10.06C36.18-.37 16.57 10 22.64 17.34c3.05 3.06 6 6.15 9.05 9.21a5.6 5.6 0 008.39 0l7.76-7.95c2.81-2.88 2.75-6.11 0-8.54zm-12 10.66a2.34 2.34 0 11-.03 0h.03zm4 .21a2.91 2.91 0 001.76-.41c.89-.64 1.22-2.55.43-3.27-1.3-1.82-4.2-2.8-6.26-2.72h-.26a7.61 7.61 0 00-5.9 2.75A2.31 2.31 0 0031.88 21c1.24-.12 1.6-1.9 3.71-1.85H36c2 .15 2.76 1.72 3.79 1.84l.05-.06zm4.67-4.33a2.34 2.34 0 001.33-4.26 14.53 14.53 0 00-9.94-4h-.5a14.54 14.54 0 00-9.72 4A2.34 2.34 0 0027 16.65c2.11 0 2.9-3.61 8.61-3.72h.69c5.29.3 6.1 3.7 8.15 3.7l.06-.03z"
              fill="#63BF8C"
            />
          </g>
          <defs>
            <clipPath id="clip0_2_4">
              <path fill="#fff" d="M0 0H184.3V33.01H0z" />
            </clipPath>
          </defs>
        </svg>
      </a>
      <a
        href="/#contact-us"
        className="bg-green-cowgenic rounded-lg flex items-center justify-center p-2"
      >
        <button className="text-white text-sm px-2">Contact Us</button>
      </a>
    </nav>
  );
};

export default Header;
