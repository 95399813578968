import * as React from "react";
import { Link } from "gatsby";
import Header from "./header";
import Footer from "./footer";
import SEO from "./seo";

type LayoutProperties = {
  children: React.ReactNode;
};

const Layout = (props: LayoutProperties) => {
  return (
    <div className="md:max-w-6xl md:m-auto">
      <SEO />
      <Header />
      <main>{props.children}</main>
      <Footer />
    </div>
  );
};

export default Layout;
