import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import PrivacyPolicy from "../pages/privacy-policy/index";

const Footer = () => {
  return (
    <footer className="bg-black absolute left-0 w-screen mt-16 sm:mt-40 lg:mt-24 text-white">
      <div className="p-4 md:max-w-6xl md:m-auto md:flex items-center justify-between  py-8 md:py-8 space-y-2">
        <div className="w-48">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 184.3 33.01">
            <defs>
              <style>{".cls-1{fill:#fff}"}</style>
            </defs>
            <g id="Layer_2" data-name="Layer 2">
              <g id="Layer_1-2" data-name="Layer 1">
                <path
                  className="cls-1"
                  d="M0 17.1V17A10.39 10.39 0 0110.73 6.59a9.46 9.46 0 018.55 4.47l-4.78 3.56a4.29 4.29 0 00-3.73-2.11c-2.26 0-3.92 2-3.92 4.44V17c0 2.59 1.62 4.55 4 4.55a4.78 4.78 0 003.85-2.07l4.7 3.4a9.93 9.93 0 01-8.92 4.59A10.32 10.32 0 010 17.1zM51.21 7h6.89l2.62 10.07L63.8 6.92h5.8l3.11 10.21L75.41 7h6.78L76 27.16h-6.06l-3.26-10.4-3.33 10.4h-6zM83.59 30.71l2.26-4.66a13.83 13.83 0 007 1.89c3.52 0 5.26-1.74 5.26-4.92v-.78a7.77 7.77 0 01-6.18 3C87 25.24 83 21.68 83 16v-.08c0-5.77 4-9.32 8.65-9.32A8 8 0 0198 9.36V7h7v15.2c0 3.67-.81 6.22-2.59 8S97.8 33 93.62 33a21.45 21.45 0 01-10.03-2.29zM98.1 16v-.08a4.1 4.1 0 00-8.18 0V16a4.1 4.1 0 008.18 0zM108 17.1V17c0-5.85 4.18-10.47 10.1-10.47 6.92 0 10.11 5.07 10.11 11v1.45h-13.4a4.14 4.14 0 004.33 3.29 6 6 0 004.44-2l3.89 3.22a10.47 10.47 0 01-8.7 4.07C112.41 27.49 108 23.31 108 17.1zm13.62-1.74c-.26-2.22-1.55-3.63-3.48-3.63s-3.11 1.44-3.48 3.63zM131.17 7h7v2.81a7.43 7.43 0 016-3.26c4.25 0 6.8 2.81 6.8 7.36V27h-7V16.17c0-2.18-1.14-3.37-2.81-3.37s-3 1.19-3 3.37V27h-7zM154.63 0H162v5.18h-7.33zm.15 7h7v20h-7zM164.92 17.1V17a10.39 10.39 0 0110.73-10.41 9.46 9.46 0 018.54 4.47l-4.77 3.56a4.3 4.3 0 00-3.74-2.11 4.15 4.15 0 00-3.92 4.49c0 2.59 1.63 4.55 4 4.55a4.76 4.76 0 003.84-2.07l4.7 3.4a9.92 9.92 0 01-8.91 4.59 10.32 10.32 0 01-10.47-10.37z"
                />
                <path
                  className="cls-1"
                  d="M35.55 27.89L22.37 15.25 27.99 9.06 35.65 7.31 43.53 10.1 48.49 14.27 35.55 27.89z"
                />
                <path
                  d="M47.84 10.06C36.18-.37 16.57 10 22.64 17.34c3.05 3.06 6 6.15 9.05 9.21a5.6 5.6 0 008.39 0l7.76-7.95c2.81-2.88 2.75-6.11 0-8.54zm-12 10.66a2.34 2.34 0 11-2.34 2.34 2.34 2.34 0 012.31-2.34zm4 .21a2.91 2.91 0 001.76-.41c.89-.64 1.22-2.55.43-3.27-1.3-1.82-4.2-2.8-6.26-2.72h-.26a7.61 7.61 0 00-5.9 2.75A2.31 2.31 0 0031.88 21c1.24-.12 1.6-1.9 3.71-1.85H36c2 .15 2.76 1.72 3.79 1.84zm4.67-4.33a2.34 2.34 0 001.33-4.26 14.53 14.53 0 00-9.94-4h-.5a14.54 14.54 0 00-9.72 4A2.34 2.34 0 0027 16.65c2.11 0 2.9-3.61 8.61-3.72h.69c5.29.3 6.1 3.7 8.15 3.7z"
                  fill="#63bf8c"
                  fillRule="evenodd"
                />
              </g>
            </g>
          </svg>
          <p className="mt-4">
            Find out how our products can help you be more productive.
          </p>
        </div>
        <div className="pt-[1.5rem] space-y-3 lg:pt-0">
          <div className="space-x-6">
            <span>PHONE</span>
            <span>+92 (321) 104-4549</span>
          </div>
          <div className="space-x-6">
            <span>EMAIL</span>
            <span>cowgenic.ai@gmail.com</span>
          </div>
          <div className="space-x-6 flex">
            <span>SOCIAL</span>
            <div>
              <svg
                fill="#FFFFFF"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24px"
                height="24px"
              >
                {" "}
                <path d="M12,2C6.477,2,2,6.477,2,12c0,5.013,3.693,9.153,8.505,9.876V14.65H8.031v-2.629h2.474v-1.749 c0-2.896,1.411-4.167,3.818-4.167c1.153,0,1.762,0.085,2.051,0.124v2.294h-1.642c-1.022,0-1.379,0.969-1.379,2.061v1.437h2.995 l-0.406,2.629h-2.588v7.247C18.235,21.236,22,17.062,22,12C22,6.477,17.523,2,12,2z" />
              </svg>
            </div>

            <div>
              <svg
                fill="#FFFFFF"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24px"
                height="24px"
              >
                {" "}
                <path d="M 8 3 C 5.239 3 3 5.239 3 8 L 3 16 C 3 18.761 5.239 21 8 21 L 16 21 C 18.761 21 21 18.761 21 16 L 21 8 C 21 5.239 18.761 3 16 3 L 8 3 z M 18 5 C 18.552 5 19 5.448 19 6 C 19 6.552 18.552 7 18 7 C 17.448 7 17 6.552 17 6 C 17 5.448 17.448 5 18 5 z M 12 7 C 14.761 7 17 9.239 17 12 C 17 14.761 14.761 17 12 17 C 9.239 17 7 14.761 7 12 C 7 9.239 9.239 7 12 7 z M 12 9 A 3 3 0 0 0 9 12 A 3 3 0 0 0 12 15 A 3 3 0 0 0 15 12 A 3 3 0 0 0 12 9 z" />
              </svg>
            </div>
            <div>
              <svg
                fill="#FFFFFF"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24px"
                height="24px"
              >
                {" "}
                <path d="M19,3H5C3.895,3,3,3.895,3,5v14c0,1.105,0.895,2,2,2h14c1.105,0,2-0.895,2-2V5C21,3.895,20.105,3,19,3z M9,17H6.477v-7H9 V17z M7.694,8.717c-0.771,0-1.286-0.514-1.286-1.2s0.514-1.2,1.371-1.2c0.771,0,1.286,0.514,1.286,1.2S8.551,8.717,7.694,8.717z M18,17h-2.442v-3.826c0-1.058-0.651-1.302-0.895-1.302s-1.058,0.163-1.058,1.302c0,0.163,0,3.826,0,3.826h-2.523v-7h2.523v0.977 C13.93,10.407,14.581,10,15.802,10C17.023,10,18,10.977,18,13.174V17z" />
              </svg>
            </div>
          </div>
          <div>
            <a href="/privacy-policy" className="underline text-gray-500">
              Privacy Policy
            </a>
          </div>
        </div>
        {/* <p className="text-xs font-light text-gray-400">2019-2022</p> */}
      </div>
    </footer>
  );
};

export default Footer;
