import React, { useRef, useEffect, MutableRefObject } from "react";
import Layout from "../components/layout";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { StaticImage } from "gatsby-plugin-image";
import { SliderButton } from "@typeform/embed-react";
import { useState } from "react";

function useArrayRef(): [MutableRefObject<any[]>, (ref: any) => void] {
  const refs = useRef<any[]>([]);
  refs.current = [];
  return [refs, (ref: any) => ref && refs.current.push(ref)];
}

// markup
const IndexPage = () => {
  const [contact, setContact] = useState({
    name: "",
    email: "",
    phone: "",
    location: "",
    message: "",
  });
  gsap.registerPlugin(ScrollTrigger);

  const ref = useRef<HTMLDivElement>(null);
  const q = gsap.utils.selector(ref);
  const [contentRefs, setContentRef] = useArrayRef();

  useEffect(() => {
    const tl = gsap.timeline();
    tl.fromTo(
      q(".simple-smart"),
      {
        autoAlpha: 0,
        y: -30,
        stagger: 0.2,
      },
      {
        autoAlpha: 1,
        y: 0,
        stagger: 0.2,
        duration: 1.0,
      }
    );
    // .fromTo(
    //   q(".other"),
    //   { autoAlpha: 0, y: -15, stagger: 0.2 },
    //   { autoAlpha: 1, y: 0, stagger: 0.2 }
    // );
  }, []);

  useEffect(() => {
    const width = window.screen.width;
    // if (width < 640) {
    const boxElement = ref.current?.querySelector("#black-box");
    if (boxElement) {
      gsap.fromTo(
        boxElement,
        {
          duration: 1,
          opacity: 0.3,
          xPercent: -100,
        },
        {
          opacity: 1,
          xPercent: 0,
          scrollTrigger: {
            trigger: boxElement,
            start: "top 100%",
            end: "center center",
            scrub: true,
          },
        }
      );
    }
    // }
  }, []);

  useEffect(() => {
    const width = window.screen.width;
    // if (width < 640) {
    contentRefs.current.forEach((ref, _) => {
      gsap.fromTo(
        ref,
        {
          opacity: 0,
          yPercent: 80,
        },
        {
          opacity: 1,
          yPercent: 0,
          scrollTrigger: {
            trigger: ref,
            start: "-100% 100%",
            end: "center center",
            scrub: true,
          },
        }
      );
    });
    // }
  });

  const [response, setResponse] = useState({
    type: "",
    message: "",
  });

  const handleChange = (e: any) => {
    setContact({ ...contact, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    try {
      console.log(contact);
      const res = await fetch("/send-email", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: contact?.name,
          email: contact?.email,
          phone: contact?.phone,
          location: contact?.location,
          message: contact?.message,
        }),
      });

      const json = await res.json();

      if (json.success) {
        setResponse({
          type: "success",
          message: "Thank you for reaching out to us.",
        });
      } else {
        setResponse({
          type: "error",
          message: json.message,
        });
      }
      console.log("response", response);
    } catch (e) {
      setResponse({
        type: "error",
        message: "An error occured while submitting the form",
      });
    }
  };

  return (
    <Layout>
      <section className="w-full mt-8 px-4 sm:flex sm:flex-row-reverse">
        <div className="sm:w-2/4 flex justify-end">
          <StaticImage
            src="../images/cow.webp"
            alt="smart collar cows in dairy farming"
            width={500}
          />
        </div>
        <div className="sm:w-2/4 sm:flex sm:flex-col sm:pr-6 sm:justify-end">
          <h1 className="font-bold text-3xl mt-6">
            <span className="text-green-cowgenic">
              <p className="simple-smart">Maximize Dairy Herd Performance</p>
              {/* <p className="other">efficient</p> */}
              {/* <p className="advantage">Smart</p> */}
              {/* <p className="advantage">economical</p>
                <p className="advantage">well-planned</p> */}
            </span>
            with Precision Farming Solutions
          </h1>
          {/* <h1 className="font-bold text-3xl mt-6">
              Simple, smart
              <br />
              animal <span className="text-green-cowgenic">care</span>
            </h1> */}
          <h2 className="text-base font-medium mt-3 max-w-md">
            Cowgenic is an always connected assistant that helps you understand
            & improve your cattle health and alerts you when any cow is in heat
            or develops a health problem
          </h2>
          <a
            href="#contact-us"
            className="mt-6 border-2 border-white bg-green-cowgenic rounded-xl h-14 w-1/2 flex items-center justify-center"
          >
            <button className="text-white text-xl font-medium">
              Contact Us
            </button>
          </a>
          {/* <SliderButton
            id="https://umarsheikhmuhammad.typeform.com/to/bMA3HO"
            className="mt-6 border-2 border-white bg-green-cowgenic rounded-xl h-14 w-1/2 flex items-center justify-center"
          >
            <p className="text-white text-xl font-medium">Contact Us</p>
          </SliderButton> */}
        </div>
      </section>
      <div
        className="relative flex flex-col items-center justify-center mx-4 mt-8"
        ref={ref}
      >
        <section className="mt-8 w-full sm:my-20 flex flex-col">
          <div className="relative mb-10 md:mb-28 sm:mb-16">
            <div className="hidden md:block opacity-3 mt-16 sm:mt-0">
              <svg
                viewBox="0 0 185 34"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_2_4)">
                  <path
                    d="M0 17.1V17A10.39 10.39 0 0110.73 6.59a9.46 9.46 0 018.55 4.47l-4.78 3.56a4.29 4.29 0 00-3.73-2.11c-2.26 0-3.92 2-3.92 4.44V17c0 2.59 1.62 4.55 4 4.55a4.78 4.78 0 003.85-2.07l4.7 3.4a9.93 9.93 0 01-8.92 4.59A10.32 10.32 0 010 17.1zM51.21 7h6.89l2.62 10.07L63.8 6.92h5.8l3.11 10.21L75.41 7h6.78L76 27.16h-6.06l-3.26-10.4-3.33 10.4h-6L51.21 7zM83.59 30.71l2.26-4.66a13.83 13.83 0 007 1.89c3.52 0 5.26-1.74 5.26-4.92v-.78a7.77 7.77 0 01-6.18 3C87 25.24 83 21.68 83 16v-.08c0-5.77 4-9.32 8.65-9.32A8 8 0 0198 9.36V7h7v15.2c0 3.67-.81 6.22-2.59 8-1.78 1.78-4.61 2.8-8.79 2.8a21.45 21.45 0 01-10.03-2.29zM98.1 16v-.08a4.1 4.1 0 00-8.18 0V16a4.1 4.1 0 008.18 0zM108 17.1V17c0-5.85 4.18-10.47 10.1-10.47 6.92 0 10.11 5.07 10.11 11v1.45h-13.4a4.14 4.14 0 004.33 3.29 6 6 0 004.44-2l3.89 3.22a10.466 10.466 0 01-8.7 4.07C112.41 27.49 108 23.31 108 17.1zm13.62-1.74c-.26-2.22-1.55-3.63-3.48-3.63s-3.11 1.44-3.48 3.63h6.96zM131.17 7h7v2.81a7.432 7.432 0 016-3.26c4.25 0 6.8 2.81 6.8 7.36V27h-7V16.17c0-2.18-1.14-3.37-2.81-3.37-1.67 0-3 1.19-3 3.37V27h-7l.01-20zM154.63 0H162v5.18h-7.33L154.63 0zm.15 7h7v20h-7V7zM164.92 17.1V17a10.399 10.399 0 013.155-7.483 10.392 10.392 0 017.575-2.927 9.46 9.46 0 018.54 4.47l-4.77 3.56a4.298 4.298 0 00-3.74-2.11 4.151 4.151 0 00-3.92 4.49c0 2.59 1.63 4.55 4 4.55a4.759 4.759 0 003.84-2.07l4.7 3.4a9.922 9.922 0 01-8.91 4.59 10.32 10.32 0 01-10.47-10.37z"
                    fill="#231F20"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M47.84 10.06C36.18-.37 16.57 10 22.64 17.34c3.05 3.06 6 6.15 9.05 9.21a5.6 5.6 0 008.39 0l7.76-7.95c2.81-2.88 2.75-6.11 0-8.54zm-12 10.66a2.34 2.34 0 11-.03 0h.03zm4 .21a2.91 2.91 0 001.76-.41c.89-.64 1.22-2.55.43-3.27-1.3-1.82-4.2-2.8-6.26-2.72h-.26a7.61 7.61 0 00-5.9 2.75A2.31 2.31 0 0031.88 21c1.24-.12 1.6-1.9 3.71-1.85H36c2 .15 2.76 1.72 3.79 1.84l.05-.06zm4.67-4.33a2.34 2.34 0 001.33-4.26 14.53 14.53 0 00-9.94-4h-.5a14.54 14.54 0 00-9.72 4A2.34 2.34 0 0027 16.65c2.11 0 2.9-3.61 8.61-3.72h.69c5.29.3 6.1 3.7 8.15 3.7l.06-.03z"
                    fill="#63BF8C"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_2_4">
                    <path fill="#fff" d="M0 0H184.3V33.01H0z" />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <p className="text-green-cowgenic text-2xl font-normal text-center sm:max-w-3xl left-0 right-0 md:absolute top-0 h-full flex items-center top-0 bottom-0 m-auto">
              Welcome to Cowgenic, where precision farming meets dairy herd
              management. Our advanced technology allows you to monitor each cow
              in your herd with unparalleled accuracy, providing real-time
              reports on their activities and health. Put the power of precision
              farming on your side and unlock the full potential of your dairy
              operation.
            </p>
          </div>
          <ul className="list-none space-y-6 flex flex-col sm:flex-row sm:items-end sm:justify-center sm:space-y-0">
            <li
              className="flex flex-col items-center text-center mt-12 sm:mt-0 sm:flex-1"
              ref={setContentRef}
            >
              <StaticImage
                src="../images/SVG/cow-health.svg"
                alt="improve cow health"
                width={125}
              ></StaticImage>
              <h3 className="mt-4 font-medium">
                Improve your
                <br />
                cattle{" "}
                <span className="font-bold text-green-cowgenic">health</span>
              </h3>
            </li>
            <li
              className="flex flex-col items-center text-center sm:flex-1"
              ref={setContentRef}
            >
              <StaticImage
                src="../images/SVG/cow-productive.svg"
                alt="increase productivity"
                width={125}
              ></StaticImage>
              <h3 className="mt-4 font-medium">
                Run a more <br />{" "}
                <span className="font-bold text-green-cowgenic">
                  productive
                </span>{" "}
                herd
              </h3>
            </li>
            <li
              className="flex flex-col items-center text-center pb-4 sm:pb-0 sm:flex-1"
              ref={setContentRef}
            >
              <StaticImage
                src="../images/SVG/farm-efficient.svg"
                alt="increase productivity"
                width={125}
              ></StaticImage>
              <h3 className="mt-4">
                Make your farm
                <br />
                operations{" "}
                <span className="font-bold text-green-cowgenic">efficient</span>
              </h3>
            </li>
          </ul>
        </section>
        <section className="mt-8 text-center bg-white sm:mt-0">
          <div className="pt-3 mb-10 sm:mb-20 ">
            <h2 className="text-3xl font-thin text-gray-500 md:text-5xl md:text-gray-400">
              <span className="text-green-cowgenic font-thin">Eating </span>
              and
              <span className="text-green-cowgenic font-thin">
                {" "}
                Rumination{" "}
              </span>
              Calculation{" "}
            </h2>
            <p className="mt-3 font-extralight text-gray-500">
              Effortless Cow Locating for Efficient Farm Management{" "}
            </p>
          </div>
          <div className=" sm:flex sm:flex-row sm:items-center">
            <div className="sm:flex-1">
              <StaticImage
                src="../images/heat-animals.webp"
                alt="heat detection for cows"
              />
            </div>
            <div className="pt-3 text-left sm:flex-1 sm:pt-0 sm:flex sm:flex-col sm:items-end md:text-xl">
              {/* <h3 className="text-lg font-extralight text-gray-500 sm:w-96 md:mb-2 md:text-xl">
                Easily identify when any animal is on heat
                <br />
              </h3> */}
              <p className="text-lg font-extralight text-gray-500 sm:w-[90%] md:text-xl">
                Cowgenic's Cow Locating feature makes finding cows needing
                attention, insemination, treatment, or fetching easier and more
                time-saving than ever before. With one click on your PC, tablet,
                or smartphone, accurately track and follow the real-time
                position of individual or multiple cows on your barn map. Fully
                integrated with Heat Detection, Health Monitoring, and Herd
                Performance Trends.
              </p>
              {/* <ul className="list-disc mt-1 sm:w-96">
                <li>
                  <p className="font-extralight text-gray-500">
                    Alerts you when an animal is on heat
                  </p>
                </li>
                <li>
                  <p className="font-extralight text-gray-500">
                    Takes away the guesswork and manual labour
                  </p>
                </li>
                <li>
                  <p className="font-extralight text-gray-500">
                    Helps your staff to make preparations for insemination in
                    advance
                  </p>
                </li>
              </ul> */}
            </div>
          </div>
          {/* ...... */}
          <div className="my-10 sm:my-20 ">
            <h2 className="text-3xl font-thin text-gray-500 md:text-5xl md:text-gray-400">
              Heat{" "}
              <span className="text-green-cowgenic font-thin">Detection </span>
            </h2>
            <p className="mt-3 font-extralight text-gray-500">
              Enhance Reproduction Efficiency with Automated Heat Detection{" "}
            </p>
          </div>
          <div className=" sm:flex sm:flex-row-reverse sm:items-center">
            <div className="sm:flex-1">
              <StaticImage
                src="../images/heat-animals.webp"
                alt="heat detection for cows"
              />
            </div>
            <div className="pt-3 text-left sm:flex-1 sm:pt-0 sm:flex sm:flex-col sm:items-start md:text-xl">
              {/* <h3 className="text-lg font-extralight text-gray-500 sm:w-96 md:mb-2 md:text-xl">
                Easily identify when any animal is on heat
                <br />
              </h3> */}
              <p className=" text-lg font-extralight text-gray-500 sm:w-[90%] md:text-xl">
                Improve your breeding success with Cowgenic's Heat Detection
                feature. Automatically track signs of heat, such as increased
                activity, sniffing, chin resting, and mounting behavior. Receive
                a clear list of cows in heat and their optimal insemination
                moment for effective breeding. Identify cows with irregular
                heats, non-cycling cows, and non-pregnant cows for targeted
                intervention."<br></br>
              </p>
              <span className="mt-2 text-lg font-extralight text-gray-500 sm:w-[90%] md:text-xl">
                Benefits: Higher conception rates, improved reproductive
                efficiency, increased breeding success.
              </span>
              {/* <ul className="list-disc mt-1 sm:w-96">
                <li>
                  <p className="font-extralight text-gray-500">
                    Alerts you when an animal is on heat
                  </p>
                </li>
                <li>
                  <p className="font-extralight text-gray-500">
                    Takes away the guesswork and manual labour
                  </p>
                </li>
                <li>
                  <p className="font-extralight text-gray-500">
                    Helps your staff to make preparations for insemination in
                    advance
                  </p>
                </li>
              </ul> */}
            </div>
          </div>

          {/* ....... */}
          <div className="my-10 sm:my-20 ">
            <h2 className="text-3xl font-thin text-gray-500 md:text-5xl md:text-gray-400">
              Herd{" "}
              <span className="text-green-cowgenic font-thin">Performance</span>{" "}
              Trends{" "}
            </h2>
            <p className="mt-3 font-extralight text-gray-500">
              Optimize Herd Performance with Data-driven Insights{" "}
            </p>
          </div>
          <div className=" sm:flex sm:flex-row sm:items-center">
            <div className="sm:flex-1">
              <StaticImage
                src="../images/heat-animals.webp"
                alt="heat detection for cows"
              />
            </div>
            <div className="pt-3 text-left sm:flex-1 sm:pt-0 sm:flex sm:flex-col sm:items-end md:text-xl">
              {/* <h3 className="text-lg font-extralight text-gray-500 sm:w-96 md:mb-2 md:text-xl">
                Easily identify when any animal is on heat
                <br />
              </h3> */}
              <p className="text-lg font-extralight text-gray-500 sm:w-[90%] md:text-xl">
                Gain valuable insights into your herd's performance with
                Cowgenic's Herd Performance Trends feature. Understand how
                external factors and management decisions influence your herd's
                productivity. Receive alerts when abnormalities are detected,
                enabling you to respond promptly. Evaluate eating patterns,
                behavior averages, consistency, cycling rates, and performance.
                Assess the impact of feeding strategies, housing conditions, and
                milking procedures. <br></br>{" "}
              </p>
              <span className="mt-2 text-lg font-extralight text-gray-500 sm:w-[90%] md:text-xl">
                Benefits: Data-driven decision- making, improved performance,
                better herd health, optimized management strategies.
              </span>
              {/* <ul className="list-disc mt-1 sm:w-96">
                <li>
                  <p className="font-extralight text-gray-500">
                    Alerts you when an animal is on heat
                  </p>
                </li>
                <li>
                  <p className="font-extralight text-gray-500">
                    Takes away the guesswork and manual labour
                  </p>
                </li>
                <li>
                  <p className="font-extralight text-gray-500">
                    Helps your staff to make preparations for insemination in
                    advance
                  </p>
                </li>
              </ul> */}
            </div>
          </div>
          {/* ....... */}

          {/* <h2 className="text-3xl font-thin text-gray-500 pt-4 mb-10 sm:my-20 md:text-5xl md:text-gray-400">
            Eating calculation and Rumination
          </h2>
          <h1>Effortless Cow Locating for Efficient Farm Management</h1>
          <div className="p-4 sm:flex sm:flex-row-reverse sm:items-center">
            <div className="sm:flex-1">
              <StaticImage
                src="../images/animal-health.webp"
                alt="detech health problems"
              />
            </div>
            <div className="py-3 text-left sm:flex-1 sm:pt-0 sm:flex sm:flex-col sm:items-center md:text-xl">
              <h3 className="text-lg font-extralight text-gray-500 sm:w-96 md:mb-2 md:text-xl">
                Identify diseases early
              </h3>
              <ul className="list-disc mt-1 sm:w-96">
                <li>
                  <p className="font-extralight text-gray-500">
                    Analyze eating and rumination habits
                  </p>
                </li>
                <li>
                  <p className="font-extralight text-gray-500">
                    Identify diseases up to 24 hours earlier
                  </p>
                </li>
                <li>
                  <p className="font-extralight text-gray-500">
                    Measure the impact of feed composition and changing weather
                  </p>
                </li>
              </ul>
            </div>
          </div> */}
        </section>
        <section className="relative w-full text-center">
          <h2 className="text-3xl font-thin text-gray-500 mb-10 mt-10 sm:mt-20 sm:mb-32 md:text-5xl md:text-gray-400">
            More{" "}
            <span className="text-green-cowgenic font-thin">efficient</span>{" "}
            farm
          </h2>
          <div
            className="bg-black font-light text-white text-sm w-3/4 mx-4 sm:w-2/5 sm:h-80 flex flex-col justify-center"
            id="black-box"
          >
            <p className="text-center pt-6 text-xl">
              Measure the{" "}
              <span className="text-bold text-green-cowgenic">impact</span> of
            </p>
            <ul className="flex flex-row flex-wrap">
              <li className="flex flex-col items-center flex-1 my-6 space-y-2">
                <StaticImage
                  src="../images/PNG/humidity.png"
                  alt="humidity effect on cows"
                  width={36}
                />
                <p>Humidity</p>
              </li>
              <li className="flex flex-col items-center flex-1 my-6 space-y-2">
                <StaticImage
                  src="../images/PNG/temperature.png"
                  alt="temperature effect on cows"
                  width={24}
                />
                <p>Temperature</p>
              </li>
              <li className="w-full flex flex-col items-center mb-6 space-y-2">
                <StaticImage
                  src="../images/PNG/feed.png"
                  alt="feed effect on cows"
                  width={60}
                />
                <p>Feed</p>
              </li>
            </ul>
          </div>
          <div
            className="p-4 absolute top-56 left-16 -z-10 sm:absolute sm:top-52 sm:left-80 sm:p-0 sm:w-2/3"
            id="measuring-picture"
          >
            <StaticImage
              src="../images/measuring-impact.webp"
              alt="measuring impact on cows"
            />
          </div>
        </section>
        <section className="text-center">
          <h2 className="text-3xl font-thin text-gray-500 mb-12 mt-32 sm:mt-52 sm:mb-20 md:text-5xl md:text-gray-400">
            <span className="text-green-cowgenic font-thin">Seamless</span> user
            experience
          </h2>
          <div className="flex flex-col sm:flex-row-reverse items-center">
            <div className="sm:flex-1">
              <StaticImage
                src="../images/analytics.webp"
                alt="seamless user experience"
              />
            </div>
            <p className="text-lg font-extralight text-gray-500 mt-8 sm:max-w-lg md:mb-2 md:text-xl sm:flex-1">
              Easy to visualise analytics help you understand the most serious
              challenges that you might be facing and help to gauge the
              effectiveness of remedial actions that you might be taking
            </p>
          </div>
          <div id="contact-us" className="pt-8 md:pt-20">
            <h2 className="text-3xl font-thin text-gray-500 mb-12 sm:mb-20 md:text-5xl md:text-gray-400">
              <span className="text-green-cowgenic font-thin">Contact</span> Us
            </h2>
            {/* onSubmit={handleSubmit} */}
            <form action="https://api.staticforms.xyz/submit" method="post">
              <input
                type="hidden"
                name="accessKey"
                value="2c61469f-991b-48ba-af5f-1fbbf4d7cd3c"
              ></input>
              <input
                type="text"
                name="honeypot"
                style={{ display: "none" }}
              ></input>

              <div className="mb-6">
                <div className="flex items-center lg:flex gap-6">
                  <label className="font-extralight text-gray-500 text-xl">
                    Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    required
                    className="lg:mt-0 mt-2 text-black px-2.5 border-b border-gray-500 focus:outline-none flex-1 w-full rounded-none"
                  ></input>
                </div>
              </div>
              <div className="mb-6">
                <div className="flex lg:flex items-center gap-6">
                  <label className="font-extralight text-gray-500 text-xl">
                    Email
                  </label>
                  <input
                    type="text"
                    name="email"
                    className="lg:mt-0 mt-2 text-black px-2.5 border-b border-gray-500 focus:outline-none flex-1 w-full rounded-none"
                  ></input>
                </div>
              </div>
              <div className="mb-6">
                <div className="flex lg:flex items-center gap-6">
                  <label className="font-extralight text-gray-500 text-xl">
                    Phone{" "}
                  </label>
                  <input
                    type="text"
                    name="phone"
                    required
                    className="lg:mt-0 mt-2 text-black px-2.5 border-b border-gray-500 focus:outline-none flex-1 w-full rounded-none"
                  ></input>
                </div>
              </div>
              <div className="mb-6">
                <div className="flex g:flex items-center gap-6">
                  <label className="font-extralight text-gray-500 text-xl">
                    Location{" "}
                  </label>
                  <input
                    type="text"
                    name="location"
                    className="lg:mt-0 mt-2 text-black px-2.5 border-b border-gray-500 focus:outline-none flex-1 w-full rounded-none"
                  ></input>
                </div>
              </div>
              <div className="mb-6">
                <div className="flex lg:flex items-center gap-6">
                  <label className="font-extralight text-gray-500 text-xl">
                    Message{" "}
                  </label>
                  <div className="flex flex-col w-full">
                    <input
                      type="text"
                      name="message"
                      required
                      className="lg:mt-0 mt-2 text-black px-2.5 border-b border-gray-500 focus:outline-none flex-1 w-full rounded-none"
                    ></input>
                  </div>
                </div>
                <div className="flex flex-col mt-6 lg:mt-0">
                  <input className="lg:mt-6 mt-2  mb-6 text-black px-2.5 border-b border-gray-500 focus:outline-none flex-1 w-full rounded-none"></input>
                  <input className="lg:mt-0 mt-2 text-black px-2.5 border-b border-gray-500 focus:outline-none flex-1 w-full rounded-none"></input>
                </div>
              </div>
              <button
                // id="https://umarsheikhmuhammad.typeform.com/to/bMA3HO"
                className="mt-12 border-2 border-white bg-green-cowgenic rounded-xl h-14 w-[30%] flex items-center justify-center"
              >
                <p className="text-white text-xl font-medium">Submit</p>
              </button>

              {/* <div>
                <input type="submit">submit</input>
              </div> */}
            </form>{" "}
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default IndexPage;

// useEffect(() => {
//   sectionRefs.current.forEach((ref, _) => {
//     gsap.to(ref, {
//       opacity: 0,
//       scrollTrigger: {
//         trigger: ref,
//         start: "top top",
//         pin: true,
//         pinSpacing: false,
//         scrub: true,
//         markers: true,
//       },
//     });
//   });
// }, []);

/* <section
          className="mt-10 w-screen bg-green-cowgenic"
          ref={setRef}
        >
          <ul className="grid grid-cols-2 gap-y-10 items-start list-none ">
            <li className="flex flex-col items-center text-center">
              <StaticImage
                src="../images/cow-health.webp"
                alt="improve cow health"
                width={125}
              ></StaticImage>
              <h3 className="text-white mt-4 font-medium">
                Easily detect when
                <br />a cow is in <span className="font-bold">heat</span>
              </h3>
            </li>
            <li className="flex flex-col items-center text-center">
              <StaticImage
                src="../images/cow-health.webp"
                alt="improve cow health"
                width={125}
              ></StaticImage>
              <h3 className="text-white mt-4 font-medium">
                Improve your
                <br />
                cattle's <span className="font-bold">health</span>
              </h3>
            </li>
            <li className="flex flex-col items-center text-center">
              <StaticImage
                src="../images/cow-productive.webp"
                alt="increase productivity"
                width={125}
              ></StaticImage>
              <h3 className="text-white mt-4 font-medium">
                Run a more <br /> <span className="font-bold">productive</span>{" "}
                herd
              </h3>
            </li>
            <li className="flex flex-col items-center text-center">
              <StaticImage
                src="../images/farm-efficient.webp"
                alt="increase productivity"
                width={125}
              ></StaticImage>
              <h3 className="text-white mt-4">
                Make your farm
                <br />
                operations <span className="font-bold">efficient</span>
              </h3>
            </li>
          </ul>
        </section> */
